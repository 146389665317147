import { FormGroup } from '@angular/forms';
import {
  ControlEfficiencyStatusDetailsDTO,
  convertResponseToControlEfficiencyStatusDetailsDTO,
} from '../../../settings/organization/dto/control-efficiency-status.dto';
import {
  ControlImplementationStatusDetailsDTO,
  convertResponseToControlImplementationStatusDetailsDTO,
} from '../../../settings/organization/dto/control-implementation-status.dto';
import {
  UserMinimalDTO,
  convertResponseToUserMinimalDTO,
} from '../../users/dto/user.dto';
import { LineChartData } from '../../../../core/modals/chart.modal';
import * as am5 from '@amcharts/amcharts5';
import { convertDateToMonthYearFormat } from '../../../../core/modals/date.modal';
import {
  ColorLabelItemDTO,
  PaginationDTO,
} from '../../../../shared/dto/table.dto';

export interface ControlTestListResponseDTO {
  items: ControlTestListDTO[];
  pagination: PaginationDTO;
}

export interface ControlTestListDTO {
  id: number;
  title: string;
  description: string;
  control_implementation_status: ColorLabelItemDTO | null;
  control_efficiency_status: ColorLabelItemDTO | null;
  score: number;
  comments: string;
  tested_source: string;
  tested_by: UserMinimalDTO | null;
  tested_at: string;
  updated_by: UserMinimalDTO | null;
  updated_at: string;
}

export interface ControlTestDTO {
  title: string;
  control_implementation_status_title_en: string;
  control_implementation_status_title_ar: string;
  control_implementation_status_color_code: string;
  control_efficiency_status_title_en: string;
  control_efficiency_status_title_ar: string;
  control_efficiency_status_color_code: string;
  description: string;
  score: number;
  comments: string;
  tested_source: string;
  tested_by_user_first_name_en: string;
  tested_by_user_first_name_ar: string;
  tested_by_user_last_name_en: string;
  tested_by_user_last_name_ar: string;
  updated_user_first_name_en: string;
  updated_user_first_name_ar: string;
  updated_user_last_name_en: string;
  updated_user_last_name_ar: string;
  tested_at: string;
  updated_at: string;
}

export interface ControlTestFormDTO {
  id?: number;
  title: string;
  description?: string;
  score?: number;
  control_implementation_status_id: number;
  control_efficiency_status_id: number;
  comments?: string;
  tested_at?: string;
  tested_by: number;
  documents?: ControlTestDocumentDTO[];
}

export interface ControlTestDetailsDTO {
  id: string;
  title: string;
  description: string;
  tested_source: string;
  tested_at: string;
  score: number;
  control_implementation_status: ControlImplementationStatusDetailsDTO | null;
  control_efficiency_status: ControlEfficiencyStatusDetailsDTO | null;
  comments: string;
  tested_by_user: UserMinimalDTO | null;
  updated_by_user: UserMinimalDTO | null;
  documents: ControlTestDocumentDetailsDTO[] | null;
}

export interface ControlTestDocumentDTO {
  id?: number;
  file_name: string;
  original_name: string;
  type: string;
  size: number;
  is_deleted_file?: boolean;
  is_new_file?: boolean;
}

export interface ControlTestDocumentDetailsDTO {
  id: number;
  file_name: string;
  original_name: string;
  url: string;
  type: string;
  size: number;
}



export function convertResponseToForm(response: any): any {
  return {
    id: response.id,
    Title: response.title,
    Description: response.description,
    EfficiencyScore: response.score,
    ImplementationStatus: response.control_implementation_status?.id,
    ControlEfficiencyStatus: response.control_efficiency_status?.id,
    Comments: response.comments,
    TestedBy: response.tested_by_user?.id,
    TestedAt: response.tested_at,
    Documents: response.documents,
  };
}

export function convertResponseToControlTestDetailsDTO(
  response: any,
  lang: string
): ControlTestDetailsDTO {
  return {
    id: response.id,
    title: response.title,
    description: response.description,
    tested_source: response.tested_source,
    tested_at: response.tested_at,
    score: response.score,
    control_implementation_status: response.control_implementation_status
      ? convertResponseToControlImplementationStatusDetailsDTO(
          response.control_implementation_status,
          lang
        )
      : null,
    control_efficiency_status: response.control_efficiency_status
      ? convertResponseToControlEfficiencyStatusDetailsDTO(
          response.control_efficiency_status,
          lang
        )
      : null,
    comments: response.comments,

    tested_by_user: response.tested_by_user
      ? convertResponseToUserMinimalDTO(response.tested_by_user, lang)
      : null,
    updated_by_user: response.updated_by_user
      ? convertResponseToUserMinimalDTO(response.updated_by_user, lang)
      : null,
    documents: response.documents ? response.documents : null,
  };
}

export interface ControlTestTrendDTO {
  tested_at: string;
  score: number;
  color_code: string;
}

export function convertResponseToLineChartData(response: any): LineChartData {
  return {
    label: convertDateToMonthYearFormat(response.tested_at),
    value: response.score,
    bulletSettings: { fill: am5.color(response.color_code) },
  };
}
