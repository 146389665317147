export interface ControlCategoryDTO {
    id: number;
    title_en: string;
    title_ar: string;
    status: string;
  }

  export interface ControlCategoryFormDTO {
    id?: number;
    title_en: string;
    title_ar: string;
    status: string;
  }

  export interface ControlCategoryDetailsDTO {
    id: number;
    title: string;
    status: string;
  }
  
  export function convertResponseToControlCategoryDetailsDTO(
    response: any,
    lang: string
  ): ControlCategoryDetailsDTO {
    return {
      id: response.id,
      title: response[`title_${lang}`],
      status: response.status,
    };
  }