import moment from 'moment';

// @todo - use class and date fromat from app setting store
export function convertPickerToRequestDateTimeFormat(date: string): string {
  return moment(date, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm:ss');
}

export function convertResponsetoPickerDateTimeFormat(date: string) {
  return moment(date, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm:ss');
}

export function convertDateToMonthYearFormat(date: string): string {
  return moment(date).format('MMM-YYYY');
}
