export interface ControlTypeDTO {
  id: number;
  title_en: string;
  title_ar: string;
  status: string;
}

export interface ControlTypeDetailsDTO {
  id: number;
  title: string;
  status: string;
}

export function convertResponseToControlTypeDetailsDTO(
  response: any,
  lang: string
): ControlTypeDetailsDTO {
  return {
    id: response.id,
    title: response[`title_${lang}`],
    status: response.status,
  };
}
