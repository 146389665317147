import {
  GugeChartData,
  LineChartThresholdConfig,
} from '../../../../core/modals/chart.modal';
import * as am5 from '@amcharts/amcharts5';

export interface ControlEfficiencyStatusDTO {
  id: number;
  title_en: string;
  title_ar: string;
  color_code: string;
  type: string;
  score_from: number;
  score_to: number;
  status: string;
}

export interface ControlEfficiencyStatusDetailsDTO {
  id: number;
  title: string;
  color_code: string;
  score_from: number;
  score_to: number;
  status: string;
}

export function convertResponseToControlEfficiencyStatusDetailsDTO(
  response: any,
  lang: string
): ControlEfficiencyStatusDetailsDTO {
  return {
    id: response.id,
    title: response[`title_${lang}`],
    color_code: response.color_code,
    score_from: response.score_from,
    score_to: response.score_to,
    status: response.status,
  };
}

export function convertResponseToGugeChartData(
  response: any,
  lang: string
): GugeChartData {
  return {
    title: response[`title_${lang}`],
    color: response.color_code,
    lowScore: response.score_from,
    highScore: response.score_to,
  };
}

export function convertResponseToLineChartThresholdConfig(
  response: any
): LineChartThresholdConfig {
  return {
    value: response.score_from,
    endValue: response.score_to,
    color: am5.color(response.color_code),
  };
}
