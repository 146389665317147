export interface ControlImplementationStatusDTO {
  id: number;
  title_en: string;
  title_ar: string;
  color_code: string;
  type: string;
  status: string;
}

export interface ControlImplementationStatusDetailsDTO {
  id: number;
  title: string;
  color_code: string;
  status: string;
}

export function convertResponseToControlImplementationStatusDetailsDTO(
  response: any,
  lang: string
): ControlImplementationStatusDetailsDTO {
  return {
    id: response.id,
    title: response[`title_${lang}`],
    color_code: response.color_code,
    status: response.status,
  };
}

